import { Task } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { DateTime } from '../../DateTime/DateTime';
import { LabelAndValue } from '../../LabelAndValue/LabelAndValue';
import { RoundOperatorInitials } from '../../Operator';

import { OperatorGroupItem } from './OperatorGroupItem/OperatorGroupItem';
import { AssigneeItem } from './AssigneeItem/AssigneeItem';
import messages from './messages';

export type TaskInfoProps = {
  task: Task;
};

export const TaskInfo = ({ task }: TaskInfoProps) => (
  <>
    <LabelAndValue
      label={<FormattedMessage {...messages.description} />}
      value={task.description || '-'}
    />
    <OperatorGroupItem
      taskId={task.taskId}
      operatorGroupId={task.operatorGroupId}
    />
    <AssigneeItem
      taskId={task.taskId}
      operatorGroupId={task.operatorGroupId}
      operatorId={task.operatorId}
    />
    {task.createdAt && task.createdBy && (
      <LabelAndValue
        label={<FormattedMessage {...messages.created} />}
        value={
          <div data-testid="TASK_CREATOR" className="flex items-center mt-1">
            <RoundOperatorInitials operatorId={task.createdBy} />
            <div className="ml-2">
              <DateTime date={task.createdAt} />
            </div>
          </div>
        }
      />
    )}
    {task.updatedAt && (
      <LabelAndValue
        label={<FormattedMessage {...messages.updated} />}
        value={<DateTime date={task.updatedAt} />}
      />
    )}
  </>
);
